import { cardPaymentApiCall } from "../utils/api/cardPaymentApi";
import { getAvailablePaymentTypes } from '../utils/payment_types';



export const sendCardDetailsAPI = async (pay_load:any) => {
    try {
      const data = await cardPaymentApiCall('mpgs/redirect/','POST',pay_load,null,false);
      return data;
      // Process the received data
    } catch (error) {
      // Handle error here
      return error;
    }
  }
