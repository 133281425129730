import React, { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, onSnapshot, initializeFirestore } from 'firebase/firestore';
import { useDispatch } from 'react-redux';
import { setNotifyDetails } from '../redux/NotifyAction';
import { notifyDetails } from '../types/api.types';
import { updateDoc, setDoc, getDoc } from 'firebase/firestore';
const firebaseConfig = {
  apiKey: "AIzaSyDGxd3PoP01JN25cPXfgRsVoc-C6XE2FTk",
  authDomain: "spemai-global.firebaseapp.com",
  projectId: "spemai-global",
  storageBucket: "spemai-global.appspot.com",
  messagingSenderId: "864345120875",
  appId: "1:864345120875:web:d33dbc1b2b02f8098dd12e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = initializeFirestore(app,{},'onepay');
const db2 = initializeFirestore(app,{});

// const db = getFirestore(app,{databaseId:'onepay'});

interface TransactionProps {
  transactionId: string;
}

const TransactionListener: React.FC<TransactionProps> = ({ transactionId }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const docRef = doc(db, "onepay", "transaction");

    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        const transactionData = data[transactionId];
        if (transactionData && transactionData.is_pay && 'is_pay' in transactionData) {
          let response:notifyDetails={
            is_loading:false,
            is_pay:true,
            is_authenticated:true,
            transaction_id:transactionId
          }

          
          dispatch(setNotifyDetails(response));
          console.log("It's authenticated 1");
        }

        if (transactionData && 'is_pay' in transactionData && !transactionData.is_pay) {
          let response:notifyDetails={
            is_loading:false,
            is_pay:false,
            is_authenticated:true,
            transaction_id:transactionId
          }

          
          dispatch(setNotifyDetails(response));
          console.log("It's authenticated 2");
        }

        if (transactionData && !transactionData.is_authenticate && 'is_authenticate' in transactionData) {
          let response:notifyDetails={
            is_loading:false,
            is_pay:false,
            is_authenticated:false,
            transaction_id:transactionId
          }

          
          dispatch(setNotifyDetails(response));
          console.log("It's authenticated 3");
        }

        // if (transactionData && transactionData.is_authenticate && 'is_authenticate' in transactionData && !('is_pay'  in transactionData)) {
        //   let response:notifyDetails={
        //     is_loading:false,
        //     is_pay:false,
        //     is_authenticated:false,
        //     transaction_id:''
        //   }

          
        //   dispatch(setNotifyDetails(response));
        //   console.log("It's authenticated 4");
        // }


        if (transactionData && transactionData.is_authenticate && 'is_authenticate' in transactionData && !('is_pay'  in transactionData) && 'is_loading' in transactionData && transactionData.is_loading) {
          let response:notifyDetails={
            is_loading:true,
            is_pay:false,
            is_authenticated:false,
            transaction_id:''
          }

          
          dispatch(setNotifyDetails(response));
          console.log("It's authenticated 4");
        }



        if (transactionData && transactionData.is_authenticate && 'is_authenticate' in transactionData && !('is_pay'  in transactionData) && 'is_loading' in transactionData && !transactionData.is_loading) {
          let response:notifyDetails={
            is_loading:true,
            is_pay:false,
            is_authenticated:false,
            transaction_id:''
          }

          
          dispatch(setNotifyDetails(response));
          console.log("It's authenticated 5");
        }

      } else {
        console.log("No such document!");
      }
    });

    return () => unsubscribe(); // Cleanup the listener on component unmount
  }, [transactionId]);

 


  return <div/>;
};

export default TransactionListener;

export const insertTransactionData = async (transactionId: string, transactionData: object) => {
  try {
    // Reference the "onepay" collection and "transaction" document
    const docRef = doc(db2, "onepay", "transaction");

    // Check if the document exists
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      // If the document exists, update it with the new transaction data
      await updateDoc(docRef, {
        [transactionId]: transactionData,
      });
      console.log(`Transaction data for ID ${transactionId} updated successfully.`);
    } else {
      // If the document doesn't exist, create it and add the transaction data
      await setDoc(docRef, {
        [transactionId]: transactionData,
      });
      console.log(`Transaction data for ID ${transactionId} created successfully.`);
    }
  } catch (error) {
    console.error("Error inserting transaction data:", error);
  }
};