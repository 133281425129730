import React, { useEffect } from 'react';
import './HelaPay.scss'; // Import your stylesheet
import { TransactionDetails } from '../../types/api.types';
import { useSelector } from 'react-redux';
import { helapaySubmitAPI } from '../../services/helapay.service';
import { v4 as uuidv4 } from 'uuid';
declare global {
  interface Window {
    helapay: {
      generateLink: (
        payment: PaymentObject,
        containerId: string,
        onComplete: () => void,
        onError: (error: string) => void
      ) => void;
    };
  }
}

interface PaymentObject {
  api_key: string;
  merchant_code: string;
  reference_id: string;
  descriptor: string;
  amount: string;
}

export const HelaPay: React.FC = () => {
  const unique = uuidv4();
  const paymentDetails: TransactionDetails = useSelector(
    (state: any) => state.payment
  );

  const generateUniqueString = () => {
    const timestamp = Date.now();
    const random = Math.random().toString(36).substring(2);
    return `${timestamp}-${random}`;
  };
  useEffect(() => {
    // Ensure the HelaPay library is loaded
    if (window.helapay && typeof window.helapay.generateLink === 'function') {
      // The payment object
      const payment: PaymentObject = {
        api_key: 'FkjzUmwb-WxJo-7OG6-730u-sQzvgkiR6R55', // API Key
        merchant_code: 'op', // Merchant Code
        reference_id: unique, // Unique Reference ID
        descriptor: 'onepay', // To Print on Customer Statement
        amount: paymentDetails.amount, // Amount in LKR
      };

      // Handler to do something after the button is generated
      const onComplete = async () => {
        let payload = {
          onepay_transaction_id: paymentDetails.gateway_transaction_id,
          hela_pay_transaction_reference: unique,
        };
        const helapay_submit = await helapaySubmitAPI(payload);
        if (helapay_submit) {
        }
        console.log('HelaPay button is ready!');
      };

      // Error Handler
      const onError = (error: string) => {
        alert(error);
      };

      // Generate the link on the 'hp-container' element.
      window.helapay.generateLink(payment, 'hp-container', onComplete, onError);
    } else {
      console.error('HelaPay library is not loaded or available.');
    }
  }, []); // Empty dependency array ensures this runs only once after the component mounts

  return (
    <div>
      <div className='ps-3 pe-3 pt-3 d-flex justify-content-center'>
        <div id="hp-container"></div>
      </div>
    </div>
  );
};
