
export const post = (obj: Record<string, any>, url: string) => {
    const mapForm = document.createElement("form");
    mapForm.target = "_self";
    mapForm.method = "POST";
    mapForm.action = url;
  
    Object.keys(obj).forEach(param => {
      const mapInput = document.createElement("input");
      mapInput.type = "hidden";
      mapInput.name = param;
      mapInput.setAttribute("value", obj[param]);
      mapForm.appendChild(mapInput);
    });
  
    document.body.appendChild(mapForm);
    mapForm.submit();
  };





  