import './Qplus.scss';
import qplus from '../../assets/qplus.png';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Button } from '../../core/Button/Button';
import { BsArrowRightShort } from 'react-icons/bs';
import { TransactionDetails } from '../../types/api.types';
import { useSelector } from 'react-redux';
import { frimiSubmitAPI } from '../../services/frimi.service';
import { getJsonHash } from '../../utils/hashing.utils';
import { useState } from 'react';
import { encryptMessage } from '../../utils/cardEncryption.util';
import { QplusNotification } from './QplusNotification/QplusNotification';
import { oneticketValidateAPI } from '../../services/oneticket.service';
import { toast } from 'react-toastify';
import { qplusSubmitAPI } from '../../services/qplus.service';

interface FormValues {
  frimi_id: string;
}
export const Qplus = () => {
  const [isLoading,setIsLoading]=useState(false);
  const [isAccept,setIsAccepting]=useState(false);
    const paymentDetails: TransactionDetails = useSelector((state: any) => state.payment);

  const initialValues: FormValues = {
    frimi_id: '',
  };
  const validationSchema = Yup.object().shape({
    frimi_id: Yup.string().required('Q+ ID is required'),
  });

  const onSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {



    setIsLoading(true)


    // if (paymentDetails.request_type == "TICKETING") {
    //   let oneticketData = {
    //     onepay_transaction_id: paymentDetails.gateway_transaction_id,
    //   };
    
    //   const validate_response: any = await oneticketValidateAPI(oneticketData);
    //   if (validate_response.status !== 100) {
    //     toast.error("Sorry you took too long to submit card details.", { autoClose: 3000 });
    
    //     // Add a small delay before redirecting
    //     setTimeout(() => {
    //       window.location.href = paymentDetails?.redirect_url;
    //     }, 3000); // Adjust the delay as needed for your toast duration
    
    //     return;
    //   }
    //   console.log(validate_response);
    // }
    
    let cust_token="eyJ0eXAiOiJKVciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoxNTg4NiOjU5fQ.y55EIrcmcspt-tBUFd"
    let request = {
      onepay_transaction_id: paymentDetails.gateway_transaction_id,
      phone_number: values.frimi_id,
    }
    // let hash_value = await getJsonHash(request)
    const frimi_response = await qplusSubmitAPI(request,cust_token)
    if(frimi_response.status==201){
      setIsLoading(false)
      setIsAccepting(true)
    }else{
      toast.error(frimi_response.response.data.message)
    }

  };
  return (
    <div>
      {!isAccept && 
      <>
      <div className="d-flex justify-content-center">
        <img className="w-25" src={qplus} alt="frimi"/>
      </div>

     <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <Form>
            <div className="row ms-3 me-3 pt-3">
              <label className="card-lbl ps-0">Combank Q+ ID</label>
              <Field
                type="text"
                name="frimi_id"
                className="txt-field"
                placeholder="Enter Combank Q+ Number"
              />
              <ErrorMessage
                className="error"
                name="frimi_id"
                component="div"
              />
            </div>


            <div className="ps-3 pe-3 pt-3">
                <Button
                   buttonText={paymentDetails.action_bt_text}
                  type='submit'
                  icon={<BsArrowRightShort />}
                  className="pay-btn"
                  style={{backgroundColor:paymentDetails.action_button_bt_color}}
                  isLoading={isLoading}
                />
              </div>
          </Form>
        )}
      </Formik>
      </>}

      {isAccept && <> <div className='pb-4 frimi-accept'>
        Now Open Q+       <img className='notify-frimi' src={qplus}/>             on your mobile. 

        </div><div className='ps-5 pe-5'><QplusNotification/></div></>}
    </div>
  );
};
