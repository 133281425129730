import cards from '../assets/cards.png';
import qr from '../assets/qr.png';
import hela from '../assets/hela.png';
import frimi from '../assets/frimi.png';
import vishwa from '../assets/vishwa.png';
import qplus from '../assets/qplus.png';

import visa from '../assets/visa.svg';
import master from '../assets/master-card.svg';
import amex from '../assets/amex.svg';
import diners from '../assets/diners1.svg';
import discover from '../assets/discover1.svg';

import { CARD, FRIMI, LQR, SAMPATH, HELAPAY, QPLUS } from '../configs/constants';

export const card_element = (
  <div className="d-flex align-items-center">
    <span className="d-flex align-items-center pe-1">
      <img src={visa} loading="lazy" alt='visa' />
    </span>
    <span className="d-flex align-items-center pe-1">
      <img src={master} loading="lazy" alt='master' />
    </span>
    <span className="d-flex align-items-center pe-1">
      <img src={amex} loading="lazy" alt='amex'/>
    </span>
    <div>
      <div className="CardFormFieldGroupIconOverflow">
        <span
          className="CardFormFieldGroupIconOverflow-Item CardFormFieldGroupIconOverflow-Item--1"
          role="presentation" style={{top:'-6px'}}
        >
          <span className="FormFieldInput-IconsIcon" role="presentation">
            <img
              src={diners}
              alt="UnionPay"
              className="BrandIcon"
              loading="lazy"
            />
          </span>
        </span>
        <span style={{top:'-6px'}}
          className="CardFormFieldGroupIconOverflow-Item CardFormFieldGroupIconOverflow-Item--2"
          role="presentation"
        >
          <span className="FormFieldInput-IconsIcon" role="presentation">
            <img
              src={discover}
              alt="JCB"
              className="BrandIcon"
              loading="lazy"
            />
          </span>
        </span>
      </div>
    </div>
  </div>
);

export const lqr_element = <img src={qr} alt={'card'}/>;
export const frimi_element = <img src={frimi} alt={'card'} />;
export const vishwa_element = <img src={vishwa} alt={'card'} />;
export const hela_element = <img width={60} src={hela} alt={'hela'} />;
export const qplus_element = <img width={30} src={qplus} alt={'qplus'} />;

export const pay_types = [
  { type: 'Bank Credit/Debit', image: card_element, services: CARD },
  { type: 'FriMi', image: frimi_element, services: FRIMI },
  { type: 'Lanka QR', image: lqr_element, services: LQR },
  
  { type: 'Sampath Vishwa', image: vishwa_element, services: SAMPATH },
  { type: 'Hela Pay', image: hela_element, services: HELAPAY },
  { type: 'Combank Q+', image: qplus_element, services: QPLUS },
];

export function getAvailablePaymentTypes(amount: any) {
    let amount_val = parseFloat(amount??'100.00')

    // Add condition to exclude HELAPAY if amount is more than 50,000
    if (amount_val >  50000) {
        return pay_types.filter(type => type.services !== HELAPAY);
    }

    return pay_types;
}
